import { compact, uniqBy } from "~/helpers/object-utility";
import { getMonthlyPrice } from "~/components/broadband-consumer/FixedOrder/steps/FixedOfferCards/FixedOfferModel";

/**
 * Sorts equipment by the order of the tags in the sortOrder list in ascending order, does not modify existing array
 * **/
function sortByTags<T extends OfferedProduct>(equipment: T[], sortOrder: string[]): T[] {
	const shallowCopy = equipment ? [...compact(equipment)] : [];

	const orderFn = (tags: string[], sortOrder: string[]) => {
		const t = tags || [];
		const noMatch = sortOrder.length;
		const sortOrderContainsMatch = t.some((tag) => sortOrder.includes(tag));

		if (sortOrderContainsMatch) {
			return Math.min(t.map((tag) => sortOrder.indexOf(tag)));
		}

		return noMatch;
	};

	return shallowCopy.sort((a, b) => orderFn(a?.offerTags, sortOrder) - orderFn(b?.offerTags, sortOrder));
}

/**
 * Strips the product name of any numbers in parentheses
 * Reusable equipment bears a reference to the "subscribedInstance" in the name
 * **/
const productName = (name) => {
	return (name || "").replace(/\(\d+\)/g, "").trim();
};

function filterReusableEquipment<T extends OfferedProduct>(equipment: T[] = []): T[] {
	return compact(equipment || []).filter((equipment) => "REUSE_EQUIPMENT" === equipment?.productType);
}

function totalMonthlyCost<T extends OfferedProduct>(product: T, instances: number = 1) {
	const productPrice = getMonthlyPrice(product) || 0;
	return productPrice * (instances || 1);
}

/**
 * Count the number of instances of each product in the list of products
 * Note that the actualProductId is used to identify the product.
 * this id should never be used in order context, that is never place an order using actualProductId
 * in general terms actualProductId should be treated as a product type identifier
 * **/
function toCountedProducts<T extends OfferedProduct>(products: T[]) {
	return uniqBy(compact(products || []), "actualProductId").map((product) => {
		const instances = products.filter((p) => p.actualProductId === product.actualProductId).length;
		return {
			name: productName(product.texts.name?.text),
			price: totalMonthlyCost(product, instances),
			imgUrl: product.imageUrl,
			amount: instances,
		};
	});
}

interface OfferedProduct {
	productId: string;
	texts: {
		name: {
			text: string;
		};
	};
	prices: Prices;
	imageUrl: string;
	actualProductId: string;
	productType: string;
	offerTags: OfferTag[];
}

enum OfferTag {
	TV_CONTENT = "TV_CONTENT",
	TWE_DECODER = "TWE_DECODER",
	WIFI_DECODER = "WIFI_EXTENDER",
	ROUTER = "ROUTER",
	BINDING = "BINDING",
	MUNICIPALITY_ESTABLISHMENT = "MUNICIPALITY_ESTABLISHMENT",
	MY_OFFER = "MY_OFFER",
	SMART_CARD = "SMART_CARD",
}

type Prices = Record<PriceType, { amount: number; currency: "NOK" | "POINTS" }>;
type PriceType = "MONTHLY" | "ONE_TIME";

export default { sortByTags, filterReusableEquipment, productName, toCountedProducts, OfferTag };
